<template>
  <div class="background">
    <div class="typeArea title">
      科研项目
    </div>
    <div
      class="searcharea"
      v-if="isshowsearch"
    >
      <div class="searcharea-title">
        <div class="title-key">
          <div class="leftborder">

            <div class="row"></div>
            <div class="column"></div>
          </div>
          <div>{{serchkeywords}}</div>
          <div class="rightborder">
            <div class="column"></div>
            <div class="row"></div>
          </div>
        </div>
        <div class="title-scribe">相关领域</div>
      </div>
      <div class="searcharea-content">
        <div
          class="content-item"
          v-for="item in keywordsList"
          :key="item.KEYWORD_ID"
          :title="item.KEYWORD_SHOW"
          @click="keyWordsClick(item)"
        >{{item.KEYWORD_SHOW}}</div>
      </div>
    </div>
    <div class="screening typeArea">
      <div class="search">
        <div class="search-image">
          <img
            @click="searchimgclick"
            src="../../../assets/image/申报指南/搜索.png"
            alt=""
          >
        </div>
        <div class="seach-input">
          <input
            v-model="serchkeywords"
            @input="searchchange"
            @keyup.enter="search($event)"
            @blur="searchblur"
            type="search"
            placeholder="请输入关键词，添加检索条件筛选相关信息，如“知识图谱”"
          >
        </div>
      </div>
      <div class="classification typeArea">
        <div class="classification-left">
          <!-- <div class="item research-field">
            <div class="item-title ">
              学科分类
            </div>
            <div
              class="item-content"
              :class="{'div-overflow-hidden-1':screeningIsShow}"
            >
              <span
                :class="{active:researchFieldChecked===-1}"
                @click="researchFieldClick(-1,$event)"
                style="cursor:pointer"
              >
                不限
              </span>
              <span
                :class="{active:item.ischecked}"
                @click="researchFieldClick(item,$event)"
                style="cursor:pointer"
                v-for="(item,index) in disciplinelist"
                :key="index"
              >{{item.SUBJECT_NAME}}</span>
            </div>
          </div> -->
          <!-- <div class="item">
            <div class="item-title">
              招标地区
            </div>
            <div
              class="item-content support-area"
              :class="{'div-overflow-hidden-1':screeningIsShow}"
            >
              <span
                :class="{active:supportAreaChecked===-1}"
                @click="supportAreaClick(-1,$event)"
                style="cursor:pointer"
              >
                全国
              </span>
              <span
                :class="{active:supportAreaChecked===item.id}"
                @click="supportAreaClick(item,$event)"
                v-for="item in supportAreagroup"
                :key="item.id"
                style="cursor:pointer"
              >{{item.AREA_NAME||item}}</span>
            </div>
          </div> -->
          <div class="item more-title">
            <div class="time">
              <div class="item-title" style="white-space: nowrap;">
                时间范围
              </div>
              <div
                class="item-content "
                :class="{'div-overflow-hidden-1':screeningIsShow}"
              >
                <span
                  :class="{active:releaseTimeChecked===-1}"
                  @click="releaseTimeClick(-1,$event)"
                >
                  不限
                </span>
                <span
                  :class="{active:releaseTimeChecked===1}"
                  @click="releaseTimeClick(1,$event)"
                >近一月</span>
                <span
                  :class="{active:releaseTimeChecked===2}"
                  @click="releaseTimeClick(2,$event)"
                >近一年</span>
                <span
                  :class="{active:releaseTimeChecked===3}"
                  @click="releaseTimeClick(3,$event)"
                >近三年</span>
              </div>
            </div>
            <div class="project-status">
              <div class="item-title">
                区域范围
              </div>
              <div
                class="item-content"
                :class="{'div-overflow-hidden-1':screeningIsShow,'div-overflow-hidden-other-1':ishideanotherCountry}"
              >
                <span
                  :class="{active:countryAreaChecked===-1}"
                  @click="countryAreaClick(-1,$event)"
                >
                  不限
                </span>
                <span
                  :class="{active:countryAreaChecked===item.COUNTRY_ID}"
                  @click="countryAreaClick(item,$event)"
                  v-for="item in arealist.slice(0,7) "
                  :key="item.COUNTRY_ID"
                >{{item.CN_NAME}}</span>

              </div>
              <span
                class="sapn-other"
                @click="anotherCountry($event)"
              ><span class="other-text">{{countryselcet}}</span>
                <div
                  class="xiaTriangle"
                  :class="{scrollxia:isshowallcountry}"
                ></div>
              </span>
              <div
                class="countryxiala"
                v-if="isshowallcountry"
              >
                <div
                  class="country-item"
                  v-for="item1 in arealist "
                  :key="item1.COUNTRY_ID"
                  @click="countryxialaclick(item1,$event)"
                >{{item1.CN_NAME}}</div>
              </div>
            </div>
          </div>

        </div>
        <div class="classification-right">
          <div
            class="pack-up"
            @click="toogleScreenstate"
          >
            <span v-if="screeningIsShow">展开</span>
            <span v-else>收起</span>
            <div
              class="shouqiarrow"
              :class="{'tozhankaiarrow':screeningIsShow}"
            ></div>
          </div>
        </div>

      </div>

    </div>
    <div class="selectedConditions typeArea">
      <div class="selectedConditions-title">
        已选条件
      </div>
      <div class="content">
        <div
          class="item"
          v-for="(item,index)  in selectedConditionsLIstanbul"
          :key="index"
        >
          <span>{{item.title}}:{{item.showcheck}}</span>
          <span @click="delSelectedConditions(item)"><img
              src="../../../assets/image/关闭.png"
              alt=""
            ></span>
        </div>
        <div
          class="clear"
          @click="clearallchecked"
        >
          <span>
            <img
              src="../../../assets/image/清除.png"
              alt=""
            >
          </span>
          <span>清空选项</span>
        </div>

      </div>
    </div>
    <div class="list typeArea">
      <div class="total">
        <div class="vertical-line"></div>
        <div>
          共<span>{{projecttotals}}</span>条结果
        </div>
      </div>
      <div class="list-table">
        <table>
          <thead>
            <tr>
              <td>项目名称</td>
              <td>所在区域</td>
              <td>承担机构</td>
              <td>资助机构</td>
              <td>项目金额</td>
              <td>项目时间</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in ScientificResearchProjectLIstanbul"
              :key="item.id"
            >
              <td @click="toGlobalResearchDetail(item)">
                <div class="tdtitle">{{item.PROJECT_NAME}}</div>
                <div
                  class="tdcontent"
                  style="color:#999999"
                  v-if="item.PROJECT_NAME_TRANSLATE!==null&&item.PROJECT_NAME_TRANSLATE!==''"
                >
                  参考译文：{{item.PROJECT_NAME_TRANSLATE}}
                </div>
              </td>
              <td>
                <!-- v-if='item.imgsrc===true' -->
                <img v-if="item.PROJECT_ORGANIZATION_FUND_COUNTRY_ID != null"
                  :src="require(`../../../assets/image/国旗数据库/`+mhq(item.PROJECT_ORGANIZATION_FUND_COUNTRY_ID)+`.svg`)"
                  alt=""
                >
                <span v-else>——</span>
              </td>
              <td :title="item.PROJECT_ORGANIZATION"> <span v-if="item.PROJECT_ORGANIZATION==''||item.PROJECT_ORGANIZATION==null||item.PROJECT_ORGANIZATION==false">——</span> <span v-else>{{item.PROJECT_ORGANIZATION}}</span></td>
              <td :title="item.PROJECT_ORGANIZATION_FUND"> <span v-if="item.PROJECT_ORGANIZATION_FUND==''||item.PROJECT_ORGANIZATION_FUND==null||item.PROJECT_ORGANIZATION_FUND==false">——</span> <span v-else>{{item.PROJECT_ORGANIZATION_FUND}}</span></td>
              <td :title="item.PROJECT_FUNDS+item.PROJECT_FUNDS_UNIT">{{item.PROJECT_FUNDS}} <span v-if="item.PROJECT_FUNDS!==null">{{item.PROJECT_FUNDS_UNIT}}</span> <span v-if="item.PROJECT_FUNDS===null">——</span></td>
              <td>
                {{item.showTimeText}}
                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="paging typeArea"
      v-if="pageTotal>1"
    >
      <ul class="pagination">
        <!-- <li>
          <span>共<span>{{pageTotal}}</span>页</span>
                </li> -->
        <!-- <li @click="homePage()">
          <a> 首页</a>
        </li> -->
        <li
          @click="prePage()"
          :class="page<=1?'disabled':''"
        >
          <a>&lt; </a>
        </li>
        <li
          v-for="(item,index) in pages"
          :class="page==item?'active':''"
          :key="index"
          @click="curPage(item)"
        >
          <a href="javascript:void(0)">{{item}}</a>
        </li>
        <li
          @click="nextPage()"
          :class="page>=pageTotal?'disabled':''"
        >
          <a>
            &gt;
          </a>
        </li>
        <!-- <li @click="endPage()">
          <a>末页 </a>
        </li> -->
        <!-- <div
          class="opt-jump"
          style="display: inline-block;"
        >
          <span
            @click="pageJump"
            style="cursor: pointer;"
          >跳转</span>
          <input
            type="text"
            v-model="jumpInput"
          />
        </div> -->
      </ul>
    </div>

    <!-- vip弹窗 -->
    <div class="z_vip" v-if="viptc">
      <div class="dt">
        <a class="close" @click="z_close">+</a>
        <img src="../../../assets/image/tc1.png" />
        <div class="h1">查看更多项目信息需要VIP会员资格哦</div>
        <div class="h2">
          您可以邀请好友免费获得VIP会员<br />资格，或直接付费秒速升级
        </div>
        <div class="anniu">
          <a @click="vip"
            ><img src="../../../assets/image/tc2.png" />付费升级</a
          >
          <a @click="vip1"
            ><img src="../../../assets/image/tc3.png" />免费升级</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getScientificResearchProjectList,getScientificResearchProjectCountt, getResearchField, getSupportArea, getarea, getKeywords, getdiscipline, getPage } from '../../../api/scientificResearchProject'
// eslint-disable-next-line no-unused-vars
import { formatDate, makePy, checkCh, mkRslt,formatDate1,formatDate2 } from '../../../util/tools'
import { Addhistoricalrecordky } from '../../../api/myRecentInformation'
export default {
  data () {
    return {
      errorImg01: 'this.src="' + require('../../../assets/image/国家国旗/中国.jpeg') + '"',
      // 控制分页
      // 总页数
      pageTotal: 0,
      // 页码
      pageNo: 1,
      // 当前页
      page: 1,
      // 每一页的条数
      pageSize: 12,
      // 总条数
      rows: 1,
      // 跳转input输入框的值
      jumpInput: '',
      // 控制筛选条件是否隐藏
      screeningIsShow: false,
      // 科研项目列表
      ScientificResearchProjectLIstanbul: [],
      // 研究领域列表
      researchField: [],
      // 学科分类列表
      disciplinelist: [],
      // 资助地区列表
      supportArea: [],
      // 本地已有图片数组
      loaclImgSrc: ['埃塞俄比亚', '安圭拉', '安提瓜岛和巴布达', '中国'],

      // 控制选中样式
      // 研究领域
      researchFieldChecked: -1,
      // 时间范围
      releaseTimeChecked: -1,
      //  区域范围
      countryAreaChecked: -1,
      // 其他国家是否隐藏
      ishideanotherCountry: true,
      // 用户已选项
      // 研究领域
      researchFieldAlreadyChecked: [],
      // 学科分类
      disciplineAlreadyChecked: [],
      // 资助地区
      supportAreaAlreadyChecked: '',
      // 发布时间
      // 1代表最近一个月，2代表最近一年，3代表最近三年
      releaseTimeAlreadyChecked: -1,
      // 区域范围
      countryAreaAlreadyChecked: '',
      // 资助金额
      grantsAlreadyChecked: -1,
      // 最低资助价格
      mingrantsAlready: '',
      // 最高资助价格
      maxgrantsAlready: '',
      // 输入关键词
      serchkeywords: '',
      // 已选条件列表
      selectedConditionsLIstanbul: [],
      // 国家地区列表
      arealist: [],
      // 是否显示所有国家下拉
      isshowallcountry: false,
      // 其他国家点击选中
      countryselcet: '其他',
      // 是否显示搜索下拉
      isshowsearch: false,
      // 相关领域数据
      keywordsList: [],
      // 项目总数
      projecttotals: '',
      z_page: "2",
      viptc: false,
    }
  },
  created () {
    // 获取初始化数据
        this.getPageNo();
    this.getData()
    this.navxuanzhong()

  },
  computed: {
    pages () {
      const start = this.page; const end = this.pageTotal
      if (end < 10) return end
      if (start <= 5) {
        return [1, 2, 3, 4, 5, 6, '...', end]
      } else if (start > end - 5) {
        return [1, '...', end - 5, end - 4, end - 3, end - 2, end - 1, end]
      } else {
        return [1, '...', start - 3, start - 2, start - 1, start, start + 1, start + 2, '...', end]
      }
    },
    supportAreagroup () {
      const arr = this.$data.supportArea
      const arr1 = this.arrSortByName(arr, 'AREA_NAME')
      const arr2 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      let arr3 = []
      arr1.forEach(item => {
        if (arr2.indexOf(makePy(item.AREA_NAME)[0].slice(0, 1)) !== -1) {
          if (arr3.indexOf(makePy(item.AREA_NAME)[0].slice(0, 1)) === -1) {
            arr3.push(makePy(item.AREA_NAME)[0].slice(0, 1))
          }
        }
      })
      arr3 = arr3.sort()
      const arr4 = []
      arr3.forEach(ite => {
        arr4.push(ite)
        arr1.forEach(item => {
          if (ite === makePy(item.AREA_NAME)[0].slice(0, 1)) {
            arr4.push(item)
          }
        })
      })
      return arr4
    }
  },
  methods: {
        getPageNo() {
      this.page = this.$store.state.pageNo;
      console.log("11111111111", this.page);
    },
     navxuanzhong(){
      localStorage.setItem("navActive", 4);
      this.$store.commit("setnavActive", 4);
    },
    // 数组按照字段首字母排序
    arrSortByName (arr, eachName) {
      if (eachName) {
        arr.forEach(function (item) {
          const temp = item[eachName]
          item.sortName = temp
        })
        return arr.sort(function (param1, param2) {
          return param1.sortName.localeCompare(param2.sortName, 'zh')
        })
      } else {
        return arr.sort(function (param1, param2) {
          return param1.localeCompare(param2, 'zh')
        })
      }
    },
    // 格式化日期格式
    formateDateList (arr) {
      arr.forEach(element => {
        if (element.create_time) {
          element.dateformate = formatDate(element.create_time)
        } else if (element.PROJECT_DATE) {
          element.dateformatestart = formatDate(parseInt(element.PROJECT_DATE)).trim().replace(/-/g, '/')
          element.dateformateend = formatDate(parseInt(element.PROJECT_DATE_END)).trim().replace(/-/g, '/')
        } else if (element.TENDER_START_TIME) {
          // element.endTimeStamp = Date.parse(element.TENDER_END_TIME)
          element.dateformateend = formatDate(parseInt(element.TENDER_END_TIME)).trim().replace(/-/g, '/')
          element.dateformatestart = formatDate(parseInt(element.TENDER_START_TIME)).trim().replace(/-/g, '/')
        } else if (element.PROJECT_START !== null) {
          // eslint-disable-next-line eqeqeq
          if (element.PROJECT_START == false) {
            element.dateformatestart = '——'
          } else {
            element.dateformatestart = formatDate(parseInt(element.GENERATE_TIME)).trim().replace(/-/g, '/').slice(0, 4)
          }
          if (element.PROJECT_END) {
            element.dateformateend = '——'
          } else {
            element.dateformateend = formatDate(parseInt(element.PROJECT_END)).trim().replace(/-/g, '/').slice(0, 4)
          }
        }
      })
    },
      // 时间转换成显示时间
    getTimeToShow (pushTime) {
      const nowtime = Date.parse(new Date())
      const time = nowtime - pushTime * 1000
      if (time < 60000) {
        return '刚刚'
      } else if (time > 60000 && time < 3600000) {
        return Math.floor(time / 60000) + '分钟前'
      } else if (time > 3600000 && time < 86400000) {
        return Math.floor(time / 3600000) + '小时前'
      } else if (time > 86400000 && time < 604800000) {
        return Math.floor(time / 86400000) + '天前'
      } else {
        return formatDate2(pushTime)
      }
    },
    // 给元素添加图片地址字段
    addimgsrc (arr) {
      const that = this
      arr.forEach(elem => {
        elem.imgsrc = that.$data.loaclImgSrc.indexOf(elem.CN_NAME) !== -1
      })
    },
    // 根据搜索关键词获取相关领域
    getkeywordsList () {
      const that = this
      const serchkeywords = this.$data.serchkeywords
      getKeywords(serchkeywords).then(res => {
        console.log(res)
        that.$data.keywordsList = res
      })
    },
    //返回数据处理
    processingReturnData (arr) {
      arr.forEach(elem => {
        elem.showTimeText = this.getTimeToShow(elem.GENERATE_TIME)
      })
      return arr
    },
    // 数据获取
    getData () {
      const that = this
      // 获取非会员页面
      getPage().then(
        (res) => {
          console.log("非会员页面", res[0].page);
          this.z_page = res[0].page
        }
      );
      // 获取科研项目列表
      getScientificResearchProjectList({ limit: that.$data.pageSize, page: that.$data.page }).then((res) => {
        console.log('res', res)
        that.formateDateList(res.data.data)
        that.addimgsrc(res.data.data)
        res.data.data = this.processingReturnData(res.data.data)
        that.$data.ScientificResearchProjectLIstanbul = res.data.data
      })
      getScientificResearchProjectCountt({ limit: that.$data.pageSize, page: that.$data.page }).then((res) => {
        that.$data.projecttotals = res.data.count
        const thepageTotal = res.data.count % that.$data.pageSize === 0 ? Math.floor(res.data.count / that.$data.pageSize) : Math.floor(res.data.count / that.$data.pageSize) + 1
        if (thepageTotal >= 5) {
          that.$data.pageTotal = 5
        } else {
          that.$data.pageTotal = thepageTotal
        }
      })
      // 获取学科列表
      getdiscipline().then(res => {
        console.log('学科分类返回', res)
        const arr = []
        res.data.forEach(ele => {
          // eslint-disable-next-line eqeqeq
          if (ele.SUBJECT_LEVEL == 1) {
            arr.push(ele)
          }
        })
        arr.forEach(ele => {
          ele.ischecked = false
        })
        that.$data.disciplinelist = arr
        console.log('学科数据处理完毕', arr)
      }, err => {
        console.log('学科分类返回异常', err)
      })
      // 获取研究领域列表
      getResearchField().then(res => {
        const arr = res.data
        arr.forEach(ele => {
          ele.ischecked = false
        })
        console.log('研究领域', res)
        that.$data.researchField = arr
      }, err => {
        console.log(err)
      })
      // 获取资助地区列表
      getSupportArea().then(res => {
        that.$data.supportArea = res.data
      }, err => {
        console.log(err)
      })
      // 获取国家区域列表
      getarea().then(res => {
        console.log('国家区域列表', res.data)
        that.$data.arealist = res.data
      }, err => {
        console.log(err)
      })
    },

    // 页面绑定事件方法
    // 研究领域点击事件
    researchFieldClick (item, event) {
      const that = this
      if (item === -1) {
        item = {
          SUBJECT_NAME: '不限',
          SUBJECT_ID: -1
        }
        that.$data.disciplineAlreadyChecked = []
        that.$data.researchFieldChecked = -1
        const arr = that.$data.disciplinelist
        arr.forEach(ele => {
          ele.ischecked = false
        })
        const arrq = that.$data.selectedConditionsLIstanbul
        arrq.forEach((ele, index) => {
          // eslint-disable-next-line eqeqeq
          if (ele.title == ' 学科分类 ') {
            arrq.splice(index, 1)
          }
        })
      } else {
        that.$data.researchFieldChecked = 1
        const arr = that.$data.disciplinelist
        arr.forEach(ele => {
          if (ele === item) {
            ele.ischecked = !ele.ischecked
            if (that.$data.disciplineAlreadyChecked.indexOf(ele.SUBJECT_ID) === -1) {
              that.$data.disciplineAlreadyChecked.push(ele.SUBJECT_ID)
            } else {
              const i = that.$data.disciplineAlreadyChecked.indexOf(ele.SUBJECT_ID)
              const arr = that.$data.disciplineAlreadyChecked
              arr.splice(i, 1)
            }
          }
        })
        const title = event.currentTarget.parentElement.previousElementSibling.innerHTML
        const conten = item
        const items = {}
        items.title = title
        const showchecklist = []
        arr.forEach(ele => {
          // eslint-disable-next-line eqeqeq
          if (ele.ischecked == true) {
            showchecklist.push(ele.SUBJECT_NAME)
          }
        })
        items.showcheck = showchecklist.join(';')
        items.content = conten
        // 每种分类不能重复添加
        const arr1 = this.$data.selectedConditionsLIstanbul.filter(item2 => {
          return item2.title !== title
        })
        this.$data.selectedConditionsLIstanbul = arr1
        if (showchecklist.length > 0) {
          this.$data.selectedConditionsLIstanbul.push(items)
        } else {
          that.$data.researchFieldChecked = -1
        }
        console.log(this.$data.disciplineAlreadyChecked)
        this.serarchByUserSelect()
      }

      // const conten = item
      // const items = {}
      // items.title = title
      // items.showcheck = conten.INDUSTRY_NAME
      // items.content = conten
      // const arr = this.$data.selectedConditionsLIstanbul.filter(item2 => {
      //   return item2.title !== title
      // })
      // this.$data.selectedConditionsLIstanbul = arr
      // this.$data.selectedConditionsLIstanbul.push(items)
      // this.$data.researchFieldChecked = item1.INDUSTRY_ID || item1
      // console.log('研究领域选择', this.$data.researchFieldChecked)
      // this.$data.researchFieldAlreadyChecked = item1.INDUSTRY_ID
      // this.serarchByUserSelect()
    },
    // 发布时间点击事件
    releaseTimeClick (item, event) {
      const item1 = item
      if (item === -1) {
        item = {
          INDUSTRY_NAME: '不限'
        }
      } else if (item === 1) {
        item = {
          INDUSTRY_NAME: '近一月'
        }
      } else if (item === 2) {
        item = {
          INDUSTRY_NAME: '近一年'
        }
      } else if (item === 3) {
        item = {
          INDUSTRY_NAME: '近三年'
        }
      }
      const title = event.currentTarget.parentElement.previousElementSibling.innerHTML
      const conten = item
      const items = {}
      items.title = title
      items.showcheck = conten.INDUSTRY_NAME
      items.content = conten
      const arr = this.$data.selectedConditionsLIstanbul.filter(item2 => {
        return item2.title !== title
      })
      this.$data.selectedConditionsLIstanbul = arr
      this.$data.selectedConditionsLIstanbul.push(items)
      this.$data.releaseTimeChecked = item1
      this.$data.releaseTimeAlreadyChecked = item1
      this.serarchByUserSelect()
    },
    // 区域范围点击事件
    countryAreaClick (item, event) {
      this.$data.isshowallcountry = false
      const that = this
      const item1 = item
      if (item === -1) {
        that.$data.countryselcet = '其他'
        item = {
          CN_NAME: '不限',
          COUNTRY_ID: '-1'
        }
      }
      const title = event.currentTarget.parentElement.previousElementSibling.innerHTML
      const conten = item
      const items = {}
      items.title = title
      items.showcheck = conten.CN_NAME
      items.content = conten
      const arr = this.$data.selectedConditionsLIstanbul.filter(item2 => {
        return item2.title !== title
      })
      this.$data.selectedConditionsLIstanbul = arr
      this.$data.selectedConditionsLIstanbul.push(items)
      this.$data.countryAreaChecked = item1.COUNTRY_ID || item1
      this.$data.countryAreaAlreadyChecked = item1.COUNTRY_ID
      this.serarchByUserSelect()
    },
    // 已选条件点击删除事件
    delSelectedConditions (item) {
      console.log(item)
      console.log(item.title)
      const arr = this.$data.selectedConditionsLIstanbul.filter(item3 => {
        return item3 !== item
      })
      this.$data.selectedConditionsLIstanbul = arr
      if (item.title.trim() === '学科分类') {
        this.$data.researchFieldChecked = -1
        this.$data.disciplineAlreadyChecked = []
        const arr = this.$data.disciplinelist
        arr.forEach(ele => {
          ele.ischecked = false
        })
        this.$data.disciplinelist = arr
      } if (item.title.trim() === '时间范围') {
        this.$data.releaseTimeChecked = -1
        this.$data.releaseTimeAlreadyChecked = ''
      } if (item.title.trim() === '区域范围') {
        this.$data.countryAreaChecked = -1
        this.$data.countryAreaAlreadyChecked = ''
      }
      if (item.title.trim() === '关键词') {
        this.$data.serchkeywords = ''
      }
      this.serarchByUserSelect()
    },
    // 搜索图标点击事件
    searchimgclick () {
      const items = {}
      items.title = '关键词'
      items.showcheck = this.$data.serchkeywords
      const arr = this.$data.selectedConditionsLIstanbul.filter(item2 => {
        return item2.title !== '关键词'
      })
      this.$data.selectedConditionsLIstanbul = arr
      this.$data.selectedConditionsLIstanbul.push(items)
      this.serarchByUserSelect()
    },
    // 搜索框失去焦点隐藏下拉
    searchblur () {
      this.$data.isshowsearch = false
    },
    // 搜索框回车触发事件
    search () {
      this.$data.isshowsearch = false
      const items = {}
      items.title = '关键词'
      items.showcheck = this.$data.serchkeywords
      const arr = this.$data.selectedConditionsLIstanbul.filter(item2 => {
        return item2.title !== '关键词'
      })
      this.$data.selectedConditionsLIstanbul = arr
      this.$data.selectedConditionsLIstanbul.push(items)
      this.serarchByUserSelect()
    },
    // 点击清空所有已选项目
    clearallchecked () {
      this.$data.selectedConditionsLIstanbul = []

      this.$data.researchFieldChecked = -1
      this.$data.disciplineAlreadyChecked = []
      this.$data.disciplinelist.forEach(ele => {
        ele.ischecked = false
      })

      this.$data.releaseTimeChecked = -1
      this.$data.releaseTimeAlreadyChecked = ''
      this.$data.countryAreaChecked = -1
      this.$data.countryAreaAlreadyChecked = ''

      this.$data.grantsChecked = -1
      this.$data.serchkeywords = ''
      this.serarchByUserSelect()
    },
    // 其他点击事件
    anotherCountry (event) {
      this.ishideanotherCountry = !this.ishideanotherCountry
      this.$data.isshowallcountry = !this.$data.isshowallcountry
    },
    // 点击标题跳转到详情页
    // 跳转到全球科研项目详情页
    toGlobalResearchDetail (item) {
      localStorage.setItem('navActive', 4)
      this.$store.commit('setnavActive', 4)
      this.$store.commit("setpageNo", this.page);
      localStorage.setItem('wid', item.PROJECT_ID)
      const id = item.PROJECT_ID
      const uid = this.$store.state.userid
      const params = {
        title_id: id,
        user_id: uid
        // type: 1
      }
      Addhistoricalrecordky(params).then((res) => {
        console.log('添加历史记录成功', res)
      }, (err) => {
        console.log('添加历史记录异常', err)
      })
      let md = item.PROJECT_MD5
      if (md === null) {
        md = ''
      }
      this.$router.push({
        name: 'scientificResearchProjectDetails',
        params: {
          id: id
        },
        query: {
          PROJECT_GOV_MD5: md
        }
      })
      window._czc.push(['_trackEvent', '点击', '跳转到科研项目详情页'])
    },
    showprojectDetails: function () {
      this.$router.push({
        path: 'scientificResearchProjectDetails',
        query: '123'
      })
    },
    // 点击各筛选按钮实时查询数据
    serarchByUserSelect () {
      const that = this
      const params = {
        // 研究领域
        scientific_classification_one: that.$data.disciplineAlreadyChecked.join(';'),
        // 招标地区
        // 一个月
        release_time_month: that.$data.releaseTimeAlreadyChecked === 1 ? formatDate(Date.parse(new Date()) / 1000) : '',
        // 一年
        release_time_year: that.$data.releaseTimeAlreadyChecked === 2 ? formatDate(Date.parse(new Date()) / 1000) : '',
        // 三年
        release_time_years: that.$data.releaseTimeAlreadyChecked === 3 ? formatDate(Date.parse(new Date()) / 1000) : '',
        // 地区
        project_organization_fund_country_id: that.$data.countryAreaAlreadyChecked,
        limit: that.$data.pageSize,
        page: that.$data.page,
        title: that.$data.serchkeywords
      }
      // 获取科研项目列表
      getScientificResearchProjectList(params).then((res) => {
        that.formateDateList(res.data.data)
        that.addimgsrc(res.data.data)
        that.$data.ScientificResearchProjectLIstanbul = res.data.data
        
      })
      getScientificResearchProjectCountt(params).then((res) => {
        that.$data.projecttotals = res.data.count
        const thepageTotal = res.data.count % that.$data.pageSize === 0 ? Math.floor(res.data.count / that.$data.pageSize) : Math.floor(res.data.count / that.$data.pageSize) + 1
        if (thepageTotal >= 5) {
          that.$data.pageTotal = 5
        } else {
          that.$data.pageTotal = thepageTotal
        }
      })
    },
    // 分页
    // 当前页
    // curPage (i) {
    //   if (i === '...') return
    //   this.page = i
    //   this.serarchByUserSelect()
    // },
    curPage(i) {
      if (i === "...") return;
      if (this.$store.state.ispro == 'true') {
        this.page = i;
        this.serarchByUserSelect();
      } else {
        if (i <= this.z_page) {
          this.page = i;
          this.serarchByUserSelect();
        } else {
          this.viptc = true;
        }
      }
      console.log(this.$store.state.ispro);
    },
    // 下一页
    nextPage() {
      if (this.page < this.pageTotal) {
        if (this.page === 5) {
        } else if (this.$store.state.ispro == 'false') {
          if(this.page >= this.z_page){
            this.viptc = true;
          } else {
            this.page = Number(this.page) + 1;
            this.serarchByUserSelect();
          }
        } else {
          this.page = Number(this.page) + 1;
          this.serarchByUserSelect();
        }
        // this.search()
      }
    },
    // 上一页
    prePage () {
      if (this.page > 1) {
        this.page = Number(this.page) - 1
        this.serarchByUserSelect()
        // this.search()
        // this.paginationByNo(--this.pageNo, this.pageSize)
      }
    },
    // 首页
    homePage () {
      this.pageNo = 1
      this.page = 1
      this.search()
    },
    // 末页
    endPage () {
      this.page = this.pageTotal
      this.search()
    },
    // 分页跳转功能
    pageJump () {
      const val = this.$data.jumpInput
      const total = this.$data.pageTotal
      if (val !== '') {
        if (val > 0 && val <= total) {
          this.$data.pageNo = this.$data.jumpInput
          this.paginationByNo(this.pageNo, this.pageSize)
        } else {

        }
      } else {

      }
    },
    mhq(a){
      if(a !=null && a!= false && a != ''){
        return a
      }else{
        return ''
      }
      
    },
    // 页面绑定事件方法
    // 筛选区域收起按钮事件绑定
    toogleScreenstate () {
      this.$data.screeningIsShow = !this.$data.screeningIsShow
    },
    // 区域范围下拉点击事件
    countryxialaclick (item, event) {
      this.$data.isshowallcountry = false
      this.$data.countryselcet = item.CN_NAME
      const item1 = item
      const title = event.currentTarget.parentElement.parentElement.firstElementChild.innerHTML
      const conten = item
      const items = {}
      items.title = title
      items.showcheck = conten.CN_NAME
      items.content = conten
      const arr = this.$data.selectedConditionsLIstanbul.filter(item2 => {
        return item2.title !== title
      })
      this.$data.selectedConditionsLIstanbul = arr
      this.$data.selectedConditionsLIstanbul.push(items)
      this.$data.countryAreaChecked = item1.COUNTRY_ID || item1
      this.$data.countryAreaAlreadyChecked = item1.COUNTRY_ID
      this.serarchByUserSelect()
    },
    // 搜索框输入显示搜索下拉
    searchchange () {
      if (this.$data.serchkeywords === '') {
        this.$data.isshowsearch = false
        this.serarchByUserSelect()
      } else {
        this.getkeywordsList()
        this.$data.isshowsearch = true
      }
    },
    // 关键词选中事件
    keyWordsClick (item) {
      this.$data.serchkeywords = item.KEYWORD_SHOW
      this.$data.isshowsearch = false
      this.search()
    },
     // 跳转分享页
    vip() {
      this.$router.push({
        path: "/pc/viproot/vip",
      });
      this.$store.commit("setgundong", true);
    },
    vip1() {
      this.$router.push({
        path: "/pc/viproot/vip",
      });
      this.$store.commit("setgundong", false);
    },
    z_close() {
      this.viptc = false;
    },
  }
}
</script>
<style >
::-webkit-scrollbar {
  width: 9px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #5e068c;
  border-radius: 21px;
  width: 20px;
  height: 63px;
}
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 7px;
}
</style>

<style lang="scss" scoped>
.searcharea {
  width: 300px;
  height: 300px;
  background-color: #fff;
  position: absolute;
  top: 192px;
  right: 1232px;
  padding: 35px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px #888888;
  .searcharea-title {
    display: flex;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    height: 15px;
    margin-bottom: 18px;
    .title-key {
      display: flex;
      margin-right: 4px;
      .leftborder {
        display: flex;
        flex-direction: column;
        justify-content: end;
        .row {
          height: 2px;
          width: 5px;
          background: #999999;
        }
        .column {
          height: 8px;
          width: 2px;
          background: #999999;
        }
      }
      .rightborder {
        margin-left: 4px;
        height: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        .row {
          height: 2px;
          width: 5px;
          background: #999999;
        }
        .column {
          height: 8px;
          width: 2px;
          background: #999999;
        }
      }
    }
  }
  .searcharea-content {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #323232;
    height: 427px;
    overflow-x: hidden;
    overflow-y: scroll;
    .content-item {
      cursor: pointer;
      box-sizing: border-box;
      // margin-bottom: 18px;
      width: 226px;
      // height: 17px;
      padding: 8px 0px;
      padding-left: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        background-color: #faf7fb;
      }
    }
  }
}
// 旋转
.scrollxia {
  transform: rotate(180deg);
}
// 版心
.typeArea {
  width: 1200px;
  margin: 0 auto;
}
// 竖线
.vertical-line {
  width: 5px;
  height: 18px;
  background-color: #9702a3;
  margin-right: 4px;
}
// div超出一行显示...
.div-overflow-hidden-1 {
  height: 31px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; //没有效果
}
.div-overflow-hidden-other-1 {
  height: 31px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; //没有效果
}
// 向上小箭头
.shouqiarrow {
  width: 5px;
  height: 5px;
  border-bottom: 3px solid #5e068c;
  border-left: 3px solid #5e068c;
  transform: rotate(135deg) translate(-5px, -7px);
}
// 改变向上箭头方向
.tozhankaiarrow {
  transform: rotate(-45deg) translate(5px, 4px);
}
// 向下三角形
.xiaTriangle {
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #999999;
  margin-left: 3px;
  margin-right: 0px !important;
}
.background {
  background: #f1f2f6;
  overflow: hidden;
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    padding-top: 27px;
    padding-bottom: 26px;
  }
  .screening {
    .search {
      display: flex;
      align-items: center;
      height: 50px;
      background-color: #fff;
      border-bottom: 1px solid #f5f5f5;
      box-sizing: border-box;
      .search-image {
        img {
          height: 16px;
          margin-left: 30px;
          margin-right: 35px;
        }
      }
      .seach-input {
        flex: 1;
        width: 100%;
        input {
          height: 15px;
          width: 100%;
          border: none;
          outline: none;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .classification {
      box-sizing: border-box;
      background-color: #fff;
      padding: 31px 30px;
      display: flex;
      padding-right: 0px;
      padding-bottom: 8px;
      .item {
        display: flex;
        margin-bottom: 27px;
        .item-title {
          height: 28px;
          display: flex;
          align-items: center;
          text-align: center;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          opacity: 1;
          margin-right: 20px;
          min-width: 60px;
        }
        .item-content {
          width: 959px;
          display: flex;
          justify-content: left;
          align-items: center;
          flex-wrap: wrap;
          margin-right: -20px;
          span {
            cursor: pointer;
            padding: 6px 12px;
            display: flex;
            align-items: center;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            opacity: 1;
            margin-right: 8px;
            margin-bottom: 5.5px;
          }

          span.active {
            color: #5e068c;
            background-color: #faf7fb;
            text-align: center;
          }
          .price {
            display: inline-block;
            display: flex;
            align-items: center;
            margin-right: 0px !important;
            input {
              box-sizing: border-box;
              border: 1px solid #5e068c;
              border-radius: 4px;
              width: 70px;
              height: 28px;
            }
            .horizontal-line {
              width: 13px;
              height: 2px;
              background-color: #e0e0e0;
              margin: 0 7px;
            }
            span {
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 50px;
              color: #999999;
              opacity: 1;
              margin-left: 6px;
            }
          }
        }
        .sapn-other {
          cursor: pointer;
          position: absolute;
          right: -50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          opacity: 1;
          margin-right: 8px;
          margin-bottom: 5.5px;
          width: 64px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .other-text {
            text-align: left;
            width: 64px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0px !important;
          }
        }
        .countryxiala {
          cursor: pointer;
          position: absolute;
          right: -102px;
          top: 30px;
          display: flex;
          flex-direction: column;
          height: 134px;
          overflow-y: scroll;
          overflow-x: hidden;
          background: #fff;
          max-height: 300px;
          .country-item {
            cursor: pointer;
            width: 64px;
            padding: 4px 7px;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            margin-right: 0px;
          }
          .country-item:hover {
            background-color: #faf7fb;
          }
        }
        // 资助地区特殊样式
        .support-area {
          .active {
            margin-right: 8px;
          }
        }
      }
      .research-field {
        margin-bottom: 17.5px;
      }
      .more-title {
        display: flex;
        align-items: baseline;
        margin-right: -41px;
        div {
          display: flex;
          margin-right: 53px;
          .item-title {
            margin-right: 20px;
          }
          .item-content {
            margin-right: -20px;
          }
          span {
            padding: 6px 10px !important;
          }
        }
        .project-status {
          position: relative;
          .item-content {
            flex: 1;
            width: 595px;
            display: flex;
            flex-wrap: wrap !important;
          }
        }
        .time {
          .item-content {
            width: 277px;
          }
        }
        .funding-jine {
          .item-content {
            width: 298px;
          }
        }
      }
      .classification-right {
        .pack-up {
          width: 131px;
          height: 100%;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 50px;
          color: #5e068c;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .selectedConditions {
    margin-top: 30px;
    background-color: #fff;
    padding: 25px 30px;
    display: flex;
    box-sizing: border-box;
    .selectedConditions-title {
      margin-right: 33px;
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .content {
      display: flex;
      .item {
        padding: 6px 12px;
        margin-bottom: 5px;
        margin-right: 8px;
        box-sizing: border-box;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9702a3;
        height: 28px;
        background-color: #fcf7fc;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-right: 5px;
          img {
            width: 15px;
          }
        }
      }
      .clear {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-right: 5px;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #b9b9b9;
          opacity: 1;
          img {
            width: 22px;
          }
        }
      }
    }
  }
  .list {
    margin-top: 29px;
    .total {
      display: flex;
      justify-content: left;
      align-items: center;
      div {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #999999;
        opacity: 1;
        span {
          color: #b55193;
        }
      }
    }
    .list-table {
      margin-top: 11px;
      margin-bottom: 23px;
      table {
        cursor: pointer;
        width: 100%;
        border-spacing: 0px;
        border-collapse: collapse;
        table-layout: fixed;
        thead {
          tr {
            border-bottom: 1px solid #f0f0f0;
            height: 55px;
            td {
              box-sizing: border-box;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: bold;
              line-height: 21px;
              color: #3b3b3b;
              opacity: 1;
              background-color: #f9f9f9;
              border: 0px;
            }
            td:nth-of-type(1) {
              text-align: left;
              width: 511px;
              padding-left: 30px;
            }
            td:nth-of-type(2) {
              width: 96px;
            }
            td:nth-of-type(3) {
              width: 215px;
            }
            td:nth-of-type(4) {
              padding-left: 10px;
              width: 193px;
            }
            td:nth-of-type(5) {
              text-align: right;
            }
            td:nth-of-type(6) {
              text-align: right;
              padding-right: 14px;
            }
          }
        }
        tbody {
          background-color: #fff;
          tr {
            border-bottom: 1px solid #f0f0f0;
            height: 90px;
            box-sizing: border-box;
            &:hover {
              background-color: #f9f9f9;
            }
            td {
              box-sizing: border-box;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #282828;
              opacity: 1;
              .tdtitle {
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 18px;
                color: #282828;
                margin-bottom: 6px;
                width: 353px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .tdcontent {
                font-size: 13px;
                width: 353px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              img {
                width: 40.21px;
                height: 27, 69px;
              }
            }
            td:nth-of-type(1) {
              padding-right: 6px !important;
              text-align: left;
              padding: 24px 30px;
              padding-right: 0px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            td:nth-of-type(2) {
              padding-right: 6px !important;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            td:nth-of-type(3) {
              padding-right: 6px !important;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            td:nth-of-type(4) {
              padding-right: 6px;
              padding-right: 6px !important;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            td:nth-of-type(5) {
              padding-right: 6px;
              width: 92.5px;
              text-align: right;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 18px;
              color: #990263;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            td:nth-of-type(6) {
              text-align: right;
              padding-right: 14px;
            }
            .tdupto {
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              color: #707070 !important;
            }
          }
        }
      }
    }
  }
  .paging {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 98px;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px;
    }
    li {
      margin: 0 4px;
      width: 50px;
      height: 50px;
      background-color: #fff;
      // border: 1px solid #b9b9b9;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #282828;
      }
    }
    .active {
      background: #5e068c;
      border: 1px solid #5e068c;
      opacity: 1;
      a {
        color: #fff;
      }
    }
  }
}
.z_vip {
  background: rgba(4, 4, 4, 0.39);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  left: 0;
  top: 0;
  .dt {
    width: 780px;
    padding-top:55px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
    opacity: 1;
    overflow: hidden;
    border-radius: 20px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    margin-top: -245px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    img {
      width: 235px;
    }
    .h1 {
      margin-top: 20px;
      font-size: 26px;
      margin-bottom: 25px;
      font-weight: bold;
      color: #dc7200;
      span{font-size: 40px;color: #990263;}
    }
    .h2 {
      margin-bottom: 33px;
      font-size: 22px;
      font-weight: 400;
      line-height: 34px;
      text-align: center;
      color: #dc7200;
    }
    .anniu {
      width: 100%;
      display: flex;
      a {
        width: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 25px;
        justify-content: center;
        height: 116px;
        background: rgba(94, 6, 140, 1);
        color: #fff;
        h1{font-size:25px;color:#121212;display: flex;flex-direction: column;line-height:1.5;position: relative;
          span{font-size: 14px;color: #4B4B4B;}
        }
        &:first-child {
          background: linear-gradient(90deg, #ffce86 0%, #ffbc76 100%);
          color: #121212;
        }
        img {
          width: 44px;
          margin-right: 15px;
        }
      }
    }
    .close {
      position: absolute;
      right: 15px;
      top: 15px;
      transform: rotate(45deg);
      color: #b5b5b5;
      font-size: 40px;
      cursor: pointer;
    }
  }
}
</style>
