import requert1 from '../util/loginrequest'
import request from '../util/request'
// 获取科研项目列表
export const getScientificResearchProjectList = (params) => {
  return requert1({
    url: '/api/demo/Project/list',
    method: 'post',
    data: {
      scientific_classification_one: params.scientific_classification_one || '',
      release_time_month: params.release_time_month || '',
      release_time_year: params.release_time_year || '',
      release_time_years: params.release_time_years || '',
      project_organization_fund_country_id: params.project_organization_fund_country_id || '',
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || ''
    }

  })
}

// 获取科研项目列表2
export const getScientificResearchProjectCountt = (params) => {
  return requert1({
    url: '/api/demo/Project/countt',
    method: 'post',
    data: {
      scientific_classification_one: params.scientific_classification_one || '',
      release_time_month: params.release_time_month || '',
      release_time_year: params.release_time_year || '',
      release_time_years: params.release_time_years || '',
      project_organization_fund_country_id: params.project_organization_fund_country_id || '',
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || ''
    }
  })
}

// 获取学科分类
export const getdiscipline = () => {
  return request({
    url: '/api/demo/ProjectGov/subject',
    method: 'get'

  })
}

// 获取研究领域
export const getResearchField = () => {
  return request({
    url: '/api/demo/ProjectGov/industry',
    method: 'get'

  })
}
// 获取招标地区
export const getSupportArea = () => {
  return request({
    url: '/api/demo/ProjectGov/pro',
    method: 'get'

  })
}
// 获取区域范围
export const getarea = () => {
  return request({
    url: '/api/demo/ProjectGov/country',
    method: 'get'

  })
}
// 获取关键词列表
export const getKeywords = (params) => {
  return request({
    url: '/api/demo/subscribe/key_words',
    method: 'post',
    data: {
      keywords: params
    }

  })
}
// 获取非会员页面地址
export const getPage = () => {
  return request({
    url: '/api/demo/search/page',
    method: 'get'

  })
}