import { render, staticRenderFns } from "./scientificResearchProject.vue?vue&type=template&id=5d7af04b&scoped=true"
import script from "./scientificResearchProject.vue?vue&type=script&lang=js"
export * from "./scientificResearchProject.vue?vue&type=script&lang=js"
import style0 from "./scientificResearchProject.vue?vue&type=style&index=0&id=5d7af04b&prod&lang=css"
import style1 from "./scientificResearchProject.vue?vue&type=style&index=1&id=5d7af04b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d7af04b",
  null
  
)

export default component.exports